import Swiper, { Navigation, Pagination } from "swiper";
const doctorsSlides = document.querySelectorAll(".slide-doctors");
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

Swiper.use([Navigation, Pagination]);

const swiper = new Swiper(".swiper1", {
    // Optional parameters
    loop: true,

    // If we need pagination
    pagination: {
        el: ".swiper-pagination",
    },
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    // Navigation arrows
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

function autoScrollSlider() {
    const sliderWrapper = document.querySelector('.slider-wrapper-doctors');
    const sliderWidth = sliderWrapper.offsetWidth;
    const slideWidth = sliderWrapper.querySelector('.slide-doctors').offsetWidth;
    const slidesCount = sliderWrapper.querySelectorAll('.slide-doctors').length;

    let offset = 0;

    setInterval(() => {
        offset = (offset + slideWidth) % (slideWidth * (slidesCount - 5));
        sliderWrapper.style.transform = `translateX(-${offset}px)`;

        if (offset === 0) {
            sliderWrapper.style.transition = 'none';
            offset = slideWidth * (slidesCount - 5);
            sliderWrapper.style.transform = `translateX(-${offset}px)`;
        } else {
            sliderWrapper.style.transition = 'transform 1s ease';
        }
    }, 3000);
}

// Запустить автоматическую прокрутку слайдера при загрузке страницы
window.onload = autoScrollSlider;
doctorsSlides.forEach((slide) => {
    slide.addEventListener("click", () => {
        const id = slide.getAttribute("id");

        document.location.href = `/doctors/${id}`;
    })
});
